import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App'
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react"
import { InteractionType } from "@azure/msal-browser"
import { PublicClientApplication } from "@azure/msal-browser"
import { BrowserRouter } from 'react-router-dom'
import { configuration } from "./authConfig"
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js"
import { reactPlugin } from "./appInsightsConfig"
import { apiRequest } from "./authConfig"

const pca = new PublicClientApplication(configuration);

const container = document.getElementById('root')
const root = createRoot(container)

root.render(<AppInsightsContext.Provider value={reactPlugin}>
    <MsalProvider instance={pca}>
        <BrowserRouter>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={apiRequest}>
                <App />
            </MsalAuthenticationTemplate>
        </BrowserRouter>
    </MsalProvider>
</AppInsightsContext.Provider>
)
