import React from "react";
import { Link } from "react-router-dom";

function Home() {
    return (<div className="text-center">
        <h1>Form Selection</h1>
        <hr />

        <div className="d-grid gap-2 button-container">
            <Link to="/Funding" className="btn btn-outline-dark btn-lg block">Funding</Link>
            <Link to="/Statements" className="btn btn-outline-dark btn-lg block disabled">Statements</Link>
        </div>
    </div>);
}

export default Home;