import React from "react"
import axios from "axios"
import Loading from "./Loading"
import ResourceError from "./ResourceError"
import { Form, Table, Modal, Button, Alert } from "react-bootstrap"
import { Paperclip } from "react-bootstrap-icons"

function Statements() {
    const [hasResourceError, setResourceError] = React.useState(false)
    const [interval, setInterval] = React.useState('currentWeek')
    const [lastElement, setLastElement] = React.useState()
    const [documentInfo, setDocumentInfo] = React.useState()
    const [index, setIndex] = React.useState(0)
    const [modalError, setModalError] = React.useState(false)
    const [showModal, setShowModal] = React.useState(false)
    const [modalData, setModalData] = React.useState()
    const [hasMore, setHasMore] = React.useState(true)
    const [axiosLoading, setAxiosLoading] = React.useState(false)

    const observer = React.useRef(
        new IntersectionObserver(
            (entries) => {
                const first = entries[0];
                if (first.isIntersecting) {
                    setIndex(i => i + 1)
                }
            })
    )

    React.useEffect(() => {
        setAxiosLoading(true)
        axios.get(`/api/Statements?interval=${interval}&index=${index}`)
            .then(response => {
                setDocumentInfo(prev => {
                    if (!prev) return response.data
                    else {
                        if (response.data.statements.length === 0) {
                            setHasMore(false)
                            return prev
                        }

                        const docInfoCopy = JSON.parse(JSON.stringify(prev))
                        const combinedData = [...docInfoCopy.statements, ...response.data.statements]
                        docInfoCopy.statements = combinedData
                        return docInfoCopy
                    }
                })

                setAxiosLoading(false)
            })
            .catch(err => {
                if (err.response) setResourceError(true)
            })
    }, [index, interval])

    React.useEffect(() => {
        if (!documentInfo) {
            setLastElement(null)
            setHasMore(true)
        }

    }, [documentInfo])

    React.useEffect(() => {
        const currentElement = lastElement;
        const currentObserver = observer.current;

        if (currentElement) {
            currentObserver.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                currentObserver.unobserve(currentElement);
            }
        }
    }, [lastElement])

    React.useEffect(() => {
        if (modalData?.id && !modalData?.img) {
            axios.get('/api/Receipts/' + modalData.id, { responseType: "blob" })
                .then(response => {
                    var reader = new FileReader();
                    reader.readAsDataURL(response.data)

                    reader.onloadend = function () {
                        setModalData(prevData => {
                            return {
                                name: prevData.name,
                                id: prevData.id,
                                img: reader.result
                            }
                        })
                    }
                })
                .catch(err => {
                    if (err.response) setModalError(err.response.data)
                })
        }
    }, [modalData])

    function getHeading(statement) {
        if (statement) return Object.getOwnPropertyNames(statement).map(pn => <th className="ws-nowrap" key={pn}>{Object.entries(documentInfo.displayNameMap).find(e => e[0] === pn)[1]}</th>)
    }

    function getRowData(statement) {
        return Object.entries(statement).map(e => {
            let tableData = '';

            if (e[0] === "receipt") {
                if (e[1]) {
                    tableData = <Paperclip
                        onClick={() => { setModalData({ name: "Receipt", id: statement.id }); setShowModal(true) }}
                        className="cursor-pointer"
                        color="black"
                        size={20}
                    />
                }

            } else tableData = e[1]

            return <td key={e[0]}>{tableData}</td>
        })
    }

    function handleIntervalChange(e) {
        setDocumentInfo(null)
        setInterval(e.currentTarget.value)
        setIndex(0)
    }

    return (hasResourceError ? <ResourceError /> : <>
        <h2>Statements</h2>

        <div style={{ maxWidth: '200px' }} className="mx-auto">
            <Form.Select disabled={axiosLoading} onChange={handleIntervalChange} value={interval} className="text-center">
                <option value="currentWeek">Current Week</option>
                <option value="priorWeek">Prior Week</option>
            </Form.Select>
        </div>

        {!documentInfo ? <Loading /> : <>
            <Modal
                show={showModal}
                onHide={() => { setModalData(''); setShowModal(false) } }
            >
                <Modal.Header closeButton>
                    <Modal.Title>{modalData?.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {modalData?.img ? <img alt="Receipt" className="modal-img" src={modalData?.img} /> : modalError
                        ? <div className="text-danger fs-12">&bull; {modalError}</div>
                        : <Loading noPadding />}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Table responsive className="mt-4" bordered>
                <thead>
                    <tr>
                        {
                            getHeading(documentInfo?.statements[0])
                        }
                    </tr>
                </thead>

                <tbody>
                    {
                        documentInfo.statements.length === 0 ? <Alert className="mt-2" variant="light">No statements found.</Alert> : documentInfo.statements.map((s, index) => hasMore && index === (documentInfo.statements.length - 1) ?
                            <tr ref={setLastElement} key={index}>{getRowData(s)}</tr>
                            : <tr key={index}>
                                {getRowData(s)}
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>}
    </>)
}

export default Statements