const appEnvironment = (() => {
    switch (process.env.REACT_APP_ENVIRONMENT) {
        case 'Production':
            return {
                frontendId: 'ca724b8d-bd7e-463a-b8ba-a5351b921d75',
                backendId: 'ec2e7d87-59ec-4c6b-86ad-fd43324db1bb',
                tenantId: 'bcc870a3-439e-47b6-b271-1dddd8ef5ed8'
            }
        default:
            return {
                frontendId: '585ce43f-4cdf-4df9-97c6-63c904d98a12',
                backendId: 'c336eb6b-f6e3-48a9-9283-70d201d9b412',
                tenantId: '42bc4a34-f02c-437b-a613-b479f4f9c54a'
            }
    }
})()

const apiRequest = {
    scopes: [`api://${appEnvironment.backendId}/Form.Submit`, `api://${appEnvironment.backendId}/Photo.Process`]
}

const configuration = {
    auth: {
        clientId: appEnvironment.frontendId,
        authority: "https://login.microsoftonline.com/" + appEnvironment.tenantId,
        redirectUri: "/",
        postLogoutRedirectUri: "/",
    }
}

export { apiRequest, configuration };