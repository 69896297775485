import React from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { PersonCircle } from "react-bootstrap-icons";
import { Nav, Navbar, Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";
import { apiRequest } from "../authConfig";

function SiteNavBar() {
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const name = accounts[0] && accounts[0].name;

    return (<Navbar className="mb-2" bg="light" expand={false}>
        <Navbar.Brand className="ms-2" as={Link} to="/">Quest TI</Navbar.Brand>
        <Nav className="ms-auto">
            {
                isAuthenticated ?
                    <>
                        <Navbar.Toggle className="me-2 border-0" aria-controls="offcanvasNavbar"><PersonCircle size={30} color="#122447" /></Navbar.Toggle>
                        <Navbar.Offcanvas
                            id="offcanvasNavbar"
                            aria-labelledby="offcanvasNavbarLabel"
                            placement="end"
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id="offcanvasNavbarLabel">Hi, {name}</Offcanvas.Title>
                            </Offcanvas.Header>
                            <hr />
                            <Offcanvas.Body>
                                <Nav className="justify-content-end flex-grow-1 pe-3">
                                    <Nav.Item className="link" onClick={() => instance.logout()}>Sign Out</Nav.Item>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </> : <Nav.Link onClick={() => instance.loginRedirect(apiRequest)}>Sign In</Nav.Link>}
        </Nav>
    </Navbar>)
}

export default SiteNavBar;