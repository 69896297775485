import React from "react";
import SiteNavBar from "./SiteNavBar";
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate } from "@azure/msal-react";

function Layout({ children }) {


    return (<MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
    >
        <SiteNavBar />
        <div className="container">
            {children}
        </div>
        <div className="mt-4"></div>
    </MsalAuthenticationTemplate>)
}

export default Layout;