import React from "react";
import axios from "axios";
import Loading from "./Loading";
import { Image } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

function FundingForm() {
    const appInsights = useAppInsightsContext();
    const metricData = {
        name: "React Component Engaged Time (seconds)",
        sampleCount: 1
    };

    const additionalProperties = { "Component Name": 'FundingForm' };
    if (process.env.REACT_APP_ENVIRONMENT === 'Production') appInsights.trackMetric(metricData, additionalProperties);

    const [isSubmitting, setIsSubmitting] = React.useState(true);
    const [loading, setLoading] = React.useState(true);
    const [showSuccess, setShowSuccess] = React.useState(false);
    const [serverError, setServerError] = React.useState();
    const [showPayroll, setShowPayroll] = React.useState(false);
    const [leads, setLeads] = React.useState();
    const [errors, setErrors] = React.useState({});
    const categoryRef = React.useRef();
    const leadRef = React.useRef();
    const amountRef = React.useRef();
    const notesRef = React.useRef();
    const imageRef = React.useRef();
    const payrollDateRef = React.useRef();
    const daysWorkedRef = React.useRef();
    const dailyRateRef = React.useRef();
    const [currentPosition, setCurrentPosition] = React.useState();

    React.useEffect(() => {
        navigator.geolocation.getCurrentPosition((pos) => {
            setCurrentPosition(pos)
            setIsSubmitting(false)
        }, (positionError) => {
            switch (positionError.code) {
                case 1:
                    setServerError("Location is required to submit records.")
                    break
                default:
                    setServerError("Unable to determine location.")
            }
        })
    }, [setCurrentPosition])

    React.useEffect(() => {
        if (!showSuccess) {
            axios.get('/api/Funding')
                .then(response => {
                    setLeads(response.data);
                    setLoading(false);
                });
        }
    }, [showSuccess, isSubmitting])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setServerError()
        setErrors({})
        setIsSubmitting(true)

        const errors = {}
        // Required error check
        if (!leadRef.current.value) errors["Lead"] = "Lead is required"
        if (!amountRef.current.value) errors["Amount"] = "Amount is required"
        if (payrollDateRef.current && !payrollDateRef.current.value) errors["PayrollDate"] = "Payroll Date is required"

        // Type Error
        if (amountRef.current.value && isNaN(amountRef.current.value)) errors["Amount"] = "Amount must be a number."

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            setIsSubmitting(false);
            return;
        }

        const file = imageRef.current?.files[0];
        const fd = new FormData();
        if (file) fd.append("formFile", file);
        fd.append("Category", categoryRef.current.value);
        fd.append("Lead", leadRef.current.value);
        fd.append("Notes", notesRef.current.value);
        fd.append("Amount", amountRef.current.value);
        if (payrollDateRef.current) fd.append("PayrollDate", payrollDateRef.current.value);
        if (daysWorkedRef.current) fd.append("DaysWorked", daysWorkedRef.current.value);
        if (dailyRateRef.current) fd.append("DailyRate", dailyRateRef.current.value);
        fd.append("PositionLatitude", currentPosition.coords.latitude)
        fd.append("PositionLongitude", currentPosition.coords.longitude)

        axios.post("/api/Funding", fd, { headers: { "Content-Type": "multipart/form-data" } })
            .then(_response => setShowSuccess(true))
            .catch(err => {
                if (err.response) {
                    setServerError(err.response.data);
                    setIsSubmitting(false);
                }
            })
    }

    const handleCategorySelect = (_e) => {
        if (categoryRef.current && categoryRef.current.value === "Payroll") setShowPayroll(true)
        else setShowPayroll(false);
    }

    const Success = () => {
        return (<>
            <div className="alert alert-success">
                Record was successfully created!
            </div>

            <div className="d-grid gap-2 button-container">
                <button onClick={() => { setLoading(true); setShowSuccess(false) }} className="btn btn-outline-dark btn-lg block">Create Another Record</button>
                <Link to="/" className="btn btn-outline-dark btn-lg block">Go Home</Link>
            </div>
        </>)
    }

    return (loading ? <Loading /> : showSuccess ? <Success /> :
        <form onSubmit={handleSubmit} className="border border-dark rounded p-3">
            <h2>Funding Form</h2>
            {serverError && <div className="fs-12 text-danger">&bull; {serverError}</div>}

            <div className="fs-12 ps-1">Category</div>
            <select defaultValue="Receipt" onChange={(e) => handleCategorySelect(e)} ref={categoryRef} className="form-select">
                <option value="Payroll">Payroll</option>
                <option value="Advance">Advance</option>
                <option value="Receipt">Receipt</option>
                <option value="Payout">Payout</option>
            </select>
            {errors.hasOwnProperty("Category") && <div className="text-danger fs-12">{errors["Category"]}</div>}

            {showPayroll &&
                <div className="bg-light rounded p-2 mt-1 mb-1">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="fs-12 ps-1">Payroll Date</div>
                            <input ref={payrollDateRef} className="form-control" type="datetime-local" />
                            {errors.hasOwnProperty("PayrollDate") && <div className="text-danger fs-12">{errors["PayrollDate"]}</div>}
                        </div>

                        <div className="col-md-6">
                            <div className="fs-12 ps-1">Days Worked</div>
                            <input ref={daysWorkedRef} className="form-control" />
                        </div>
                    </div>

                    <div className="fs-12 ps-1">Daily Rate</div>
                    <div className="input-group">
                        <div className="input-group-text">
                            &#0036;
                        </div>

                        <input ref={dailyRateRef} className="form-control" />
                    </div>
                </div>}

            <div className="fs-12 ps-1">Lead</div>
            <select ref={leadRef} className="form-select">
                <option></option>
                {
                    leads && leads.map((lead, index) => <option key={index} value={lead}>{lead}</option>)
                }
            </select>
            {errors.hasOwnProperty("Lead") && <div className="text-danger fs-12">{errors["Lead"]}</div>}


            <div>
                <div className="fs-12 ps-1">Amount</div>
                <div className="input-group">
                    <div className="input-group-text">
                        &#0036;
                    </div>

                    <input ref={amountRef} className="form-control" />
                </div>
            </div>
            {errors.hasOwnProperty("Amount") && <div className="text-danger fs-12">{errors["Amount"]}</div>}

            <div>
                <div className="fs-12 ps-1">Notes</div>
                <textarea ref={notesRef} className="form-control" rows="2"></textarea>
            </div>

            <div className="fs-12 ps-1">Receipt</div>
            <div className="input-group mb-3">
                <label className="input-group-text" htmlFor="inputGroupFile01">
                    <Image className="cursor-pointer" size="20" />
                </label>
                <input ref={imageRef} type="file" className="form-control" id="inputGroupFile01" />
            </div>

            <div className="d-grid gap-2 button-container mt-4">
                <button disabled={isSubmitting} type="submit" className="btn btn-dark btn-lg block">Submit {isSubmitting && <Spinner color="white" size="sm" animation="border" /> }</button>
            </div>
        </form>);
}

export default FundingForm;