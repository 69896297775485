import React from "react";
import axios from "axios";
import Layout from "./components/Layout";
import Home from "./components/Home";
import Funding from "./components/FundingForm";
import Statements from "./components/Statements";
import ResourceError from "./components/ResourceError";
import Loading from "./components/Loading";
import { apiRequest } from "./authConfig";
import { useMsal } from "@azure/msal-react";
import { Route, Routes } from "react-router-dom";

function App() {
    const { instance, accounts } = useMsal();
    const [finishedSigning, setFinishedSigning] = React.useState(false)

    React.useEffect(() => {
        const tokenReq = {
            ...apiRequest,
            account: accounts[0]
        }

        instance.acquireTokenSilent(tokenReq).then((response) => {
            axios.defaults.headers.common['Authorization'] = `Bearer ${response.accessToken}`
            setFinishedSigning(true)
        })
    }, [instance, accounts])

    return (!finishedSigning ? <Loading /> :
        <Layout>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/Funding" element={<Funding />} />
                <Route path="/Statements" element={<Statements />} />
                <Route path="*" element={<ResourceError />} />
            </Routes>
        </Layout>
    )
}

export default App;